import React, { useState } from "react";

import logoEden from "../../images/icon/logo-eden.png";
import successImage from "../../images/success-booking.png";
import Menu from "../../layouts/Menu";
function SuccessScreen() {
  const [isOpenNav, setIsOpenNav] = useState(false);
  return (
    <div
      className=" w-screen min-h-screen bg-[#183a29] "
      style={{
        backgroundImage: 'url("/background-eden.png")',
        backgroundPosition: "center top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Menu />
      {/*  */}
      <div className="container mx-auto min-h-screen flex flex-col justify-center items-center  relative">
        <div className="pt-10"></div>

        <img className="md:w-1/3 2/3 mx-auto my-2" src={logoEden} />
        <div className="my-3 text-center font-bold text-white md:text-2xl">
          Votre réservation a bien été confirmer
        </div>
        <a
          href="/"
          className="text-white my-2 flex flex-row justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
          <div>Retour</div>
        </a>
      </div>
    </div>
  );
}

export default SuccessScreen;
