import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { createAccount, login } from "../../redux/actions/userActions";
import Alert from "../../components/Alert";
import { useNavigate } from "react-router-dom";
import Menu from "../../layouts/Menu";
import InputModel from "../../components/InputModel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import fr from "react-phone-input-2/lang/fr.json";
import { toast } from "react-toastify";
import isValidPhoneNumber, { parsePhoneNumber } from "libphonenumber-js/min";

function InscriptionScreen() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [countryInfo, setCountryInfo] = useState({
    countryCode: "",
    dialCode: "",
    format: "",
    name: "",
  });

  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");

  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");

  const [otherPhone, setOtherPhone] = useState("");
  const [otherPhoneError, setOtherPhoneError] = useState("");

  const [dateNaissance, setDateNaissance] = useState("");
  const [errorDateNaissance, setErrorDateNaissance] = useState("");

  const [langue, setLangue] = useState("");
  const [langueError, setLangueError] = useState("");

  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState("");

  const [type, setType] = useState("");
  const [typeError, setTypeError] = useState("");

  const [isAdd, setIsAdd] = useState(false);
  const [eventType, setEventType] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  // const redirect = '/dashboard'

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  const newAccount = useSelector((state) => state.createAccount);
  const {
    errorCreateCompteClient,
    successCreateCompteClient,
    loadingCreateCompteClient,
  } = newAccount;

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  useEffect(() => {
    if (successCreateCompteClient) {
      dispatch(login(email, password));
      setLoadEvent(false);
    }
  }, [successCreateCompteClient]);

  return (
    <div
      className="w-screen h-screen bg-cover bg-center bg-no-repeat bg-opacity-25 "
      style={{
        backgroundImage: 'url("/background-eden.png")',
        backgroundPosition: "center top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Menu />
      {/*  */}
      <div className="flex justify-center items-center min-h-screen mt-12">
        <div className="bg-white shadow-lg rounded mx-3 px-8 pt-6 pb-8 mb-4 md:w-[580px] w-screen">
          <h2 className="text-2xl mb-6">Créez votre compte</h2>
          {errorCreateCompteClient && (
            <Alert type="error" message={errorCreateCompteClient} />
          )}

          <div className="my-2 mx-2">
            <div className="md:py-2 md:flex ">
              <InputModel
                label="Email*"
                type="email"
                placeholder=""
                value={email}
                onChange={(v) => setEmail(v.target.value)}
                error={emailError}
              />
            </div>
            <div className="md:py-2 md:flex ">
              <InputModel
                label="Mot de passe*"
                type="password"
                placeholder=""
                value={password}
                onChange={(v) => setPassword(v.target.value)}
                error={passwordError}
              />
            </div>
            {/* fisrt name & last name */}
            <div className="md:py-2 md:flex ">
              <InputModel
                label="Nom*"
                type="text"
                placeholder=""
                value={firstName}
                onChange={(v) => setFirstName(v.target.value)}
                error={errorFirstName}
              />

              <InputModel
                label="Prénom*"
                type="text"
                placeholder=""
                value={lastName}
                onChange={(v) => setLastName(v.target.value)}
                error={errorLastName}
              />
            </div>
            <div className="md:py-2 md:flex ">
              <div className=" relative  w-full ">
                <label
                  className={` absolute bg-[#FFFFFF] text-[#898989] mt-[-16px] text-[14px]  ml-[7px] px-[5px]  line-clamp-1 z-99999	`}
                >
                  Mobile*
                </label>
                <PhoneInput
                  value={phone}
                  searchStyle={{ width: "100%", zIndex: "999999999" }}
                  localization={fr}
                  searchClass="w-full"
                  onChange={(phonevalue, data) => {
                    setPhone(phonevalue);

                    setCountryInfo(data);
                  }}
                />
                {errorPhone && (
                  <p className="text-[9px] italic text-danger leading-none mt-1">
                    {errorPhone}
                  </p>
                )}
              </div>
            </div>

            {/* date and nation */}
            <div className="md:py-2 md:flex md:mt-0 mt-5 ">
              <InputModel
                label="Date de naissance"
                type="date"
                placeholder=""
                value={dateNaissance}
                onChange={(v) => {
                  if (v.target.value !== "") {
                    const parsedDate = new Date(v.target.value);
                    setDateNaissance(parsedDate.toISOString().split("T")[0]);
                  }
                }}
                error={errorDateNaissance}
              />
            </div>
          </div>

          <div className="flex md:flex-row flex-col items-center justify-center mb-3">
            <button
              onClick={() => {
                var check = true;
                setEmailError("");
                setPasswordError("");
                setErrorFirstName("");
                setErrorLastName("");
                setErrorDateNaissance("");
                setErrorPhone("");

                if (email === "") {
                  check = false;
                  setEmailError("Ce champ est obligatoire");
                } else {
                  const regex = /^[^\s@]+@[^\s@]+\.[^\s@.]{2,}$/;
                  if (!regex.test(email)) {
                    check = false;
                    setEmailError("E-mail est incorrect");
                  }
                }
                if (password === "") {
                  check = false;
                  setPasswordError("Ce champ est obligatoire");
                }
                if (firstName === "") {
                  check = false;
                  setErrorFirstName("Ce champ est obligatoire");
                }
                if (lastName === "") {
                  check = false;
                  setErrorLastName("Ce champ est obligatoire");
                }
                if (phone === "") {
                  check = false;
                  setErrorPhone("Ce champ est obligatoire");
                } else {
                  if (
                    !countryInfo.format ||
                    countryInfo.format === undefined ||
                    countryInfo.format === null
                  ) {
                    check = false;
                    setErrorPhone("Numéro de téléphone est incorrect");
                  } else {
                    // const regexPattern = countryInfo.format
                    const phoneNumber = parsePhoneNumber(
                      phone,
                      countryInfo.countryCode.toUpperCase()
                    );
                    if (!phoneNumber.isValid()) {
                      check = false;
                      setErrorPhone("Numéro de téléphone est incorrect");
                    }
                  }
                }

                if (check) {
                  setLoadEvent(true);
                  try {
                    dispatch(
                      createAccount({
                        first_name: firstName,
                        last_name: lastName,
                        phone: phone.includes("") ? phone : "+" + phone,
                        email: email,
                        password: password,
                        date_birth: dateNaissance === "" ? null : dateNaissance,
                      })
                    );
                    setLoadEvent(false);
                  } catch (error) {
                    console.error(error);
                    setLoadEvent(false);
                  }
                  //
                } else {
                  toast.error(
                    "Certains champs sont obligatoires veuillez vérifier"
                  );
                }
              }}
              disabled={loadEvent || loading || loadingCreateCompteClient}
              className="border border-[darkslategrey] bg-[darkslategrey] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {loadEvent || loading || loadingCreateCompteClient ? (
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 mx-2 text-gray-200 animate-spin dark:text-gray-600 fill-danger"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Connexion"
              )}
            </button>
          </div>
          <div className="flex  flex-col items-center justify-between">
            <div className="flex flex-row justify-between items-center">
              <div className="">
                Vous avez un compte ?{" "}
                <a href="/login" className=" mx-1 text-[darkslategrey]">
                  {" "}
                  Connexion
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InscriptionScreen;
