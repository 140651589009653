import React, { useEffect, useRef, useState } from "react";

import logoEden from "../../images/icon/logo-eden-nv.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InputModel from "../../components/InputModel";
import LayoutSection from "../../components/LayoutSection";
import { TABLES, TABLETYPE } from "../../constants";
import {
  addNewReservation,
  checkReservation,
} from "../../redux/actions/reservationActions";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import { getUserProfile } from "../../redux/actions/userActions";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import fr from "react-phone-input-2/lang/fr.json";
import Menu from "../../layouts/Menu";
import isValidPhoneNumber, { parsePhoneNumber } from "libphonenumber-js/min";

function ConfirmScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { type, date } = useParams();

  //
  const [reservationDate, setReservationDate] = useState("");
  const [reservationDateError, setReservationDateError] = useState("");

  const [reservationTime, setReservationTime] = useState("");
  const [reservationTimeError, setReservationTimeError] = useState("");

  const [reservationPax, setReservationPax] = useState(1);
  const [reservationPaxError, setReservationPaxError] = useState("");

  const [reservationTypeTable, setReservationTypeTable] = useState("");
  const [reservationTypeTableError, setReservationTypeTableError] =
    useState("");

  const [reservationTable, setReservationTable] = useState("");
  const [reservationTableError, setReservationTableError] = useState("");

  const [prescriber, setPrescriber] = useState("");
  const [prescriberError, setPrescriberError] = useState("");

  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const [status, setStatus] = useState("RESERVED");
  const [statusError, setStatusError] = useState("");

  const [aCompte, setACompte] = useState(0);
  const [aCompteError, setACompteError] = useState("");

  const [offert, setOffert] = useState("");
  const [offertError, setOffertError] = useState("");

  const [occassion, setOccassion] = useState("");
  const [occassionError, setOccassionError] = useState("");

  const [countryInfo, setCountryInfo] = useState({
    countryCode: "",
    dialCode: "",
    format: "",
    name: "",
  });

  // client
  const [reservationClient, setReservationClient] = useState("");
  const [reservationClientError, setReservationClientError] = useState("");

  const [isClientExist, setIsClientExist] = useState(false);
  const [isClientExistError, setIsClientExistError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");

  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");

  const [otherPhone, setOtherPhone] = useState("");
  const [otherPhoneError, setOtherPhoneError] = useState("");

  const [dateNaissance, setDateNaissance] = useState("");
  const [errorDateNaissance, setErrorDateNaissance] = useState("");

  const [langue, setLangue] = useState("");
  const [langueError, setLangueError] = useState("");

  const [statusClient, setStatusClient] = useState("");
  const [statusClientError, setStatusClientError] = useState("");

  const [typeError, setTypeError] = useState("");

  //

  const [isAddReservation, setIsAddReservation] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");
  //

  const reservationCheck = useSelector((state) => state.checkReservation);
  const {
    loadingReservationCheck,
    successReservationCheck,
    errorReservationCheck,
    // isFirstVip,
    // isTwoVip,
    // isBack,
    // isDance,
  } = reservationCheck;
  const isFirstVip = true;
  const isTwoVip = true;
  const isBack = true;
  const isDance = true;

  const addReservation = useSelector((state) => state.createNewReservation);
  const { loadingReservationAdd, errorReservationAdd, successReservationAdd } =
    addReservation;
  const blockedDaysOfWeek = [1, 2, 3];

  const isBlockedDay = (date) => {
    const dayOfWeek = date.getDay();
    return blockedDaysOfWeek.includes(dayOfWeek);
  };
  const isValidDateAndAfterToday = (date) => {
    date = new Date(date);
    console.log(date instanceof Date);
    console.log(isNaN(date));
    if (!(date instanceof Date) || isNaN(date)) {
      console.log("here " + date);
      return false;
    }

    if (isBlockedDay(date)) {
      return false;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Ensure today is at midnight to only compare dates
    console.log("here  2 " + date + " ;; " + (date > today));
    return date >= today;
  };

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo, error, loading } = userLogin;

  // const profileUser = useSelector((state) => state.getProfileUser);
  // const {
  //   loadingUserProfile,
  //   errorUserProfile,
  //   successUserProfile,
  //   userProfile,
  // } = profileUser;

  // useEffect(() => {
  //   if (errorUserProfile) {
  //     localStorage.removeItem("userInfoEdenBooking");
  //     document.location.href = "/";
  //   }
  // }, [errorUserProfile]);

  // useEffect(() => {
  //   if (userProfile !== undefined && userProfile !== null) {
  //     setEmail(userProfile.email);
  //   }
  // }, [userProfile]);

  const redirect = "/";

  // useEffect(() => {
  //   if (!userInfo) {
  //     navigate(redirect);
  //   } else {
  //     dispatch(getUserProfile());
  //   }
  // }, [navigate, userInfo]);

  useEffect(() => {
    if (
      !TABLETYPE.includes(type) ||
      date === "" ||
      !isValidDateAndAfterToday(date)
    ) {
      navigate("/");
    } else {
      // dispatch(checkReservation(date));
      setReservationDate(date);
      setReservationTypeTable(type);
    }
  }, [navigate, type, date]);

  useEffect(() => {
    if (successReservationCheck) {
      console.log(type);

      // "The Garden - 2eme ligne",
      // "Disco jungle - 1ere ligne",
      // "Disco jungle - 2eme ligne",
      if (type === "The Garden - 1ere ligne") {
        if (!isFirstVip) {
          navigate("/");
        }
      } else if (type === "The Garden - 2eme ligne") {
        if (!isFirstVip) {
          navigate("/");
        }
      } else if (type === "Disco jungle - 1ere ligne") {
        if (!isTwoVip) {
          navigate("/");
        }
      } else if (type === "Disco jungle - 2eme ligne") {
        if (!isTwoVip) {
          navigate("/");
        }
      } else if (type === "BACK STAGE") {
        if (!isBack) {
          navigate("/");
        }
      } else if (type === "DANCE FLOOR") {
        if (!isDance) {
          navigate("/");
        }
      }
    }
  }, [successReservationCheck]);

  return (
    <div
      className=" w-screen min-h-screen  "
      style={{
        backgroundImage: 'url("/background-eden.png")',
        backgroundPosition: "center top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Menu />
      {/*  */}
      <div className="pt-30 container mx-auto px-3">
        <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
          <h4 className=" uppercase font-semibold ">
            Ajouter une nouvelle réservation
          </h4>
        </div>
        {/*  */}
        <div className="flex md:flex-row flex-col ">
          <div className=" w-full px-1 py-1">
            <LayoutSection
              title="Informations"
              styles="bg-[darkslategrey] text-white font-bold"
            >
              {/*  */}
              <div className="md:py-2 md:flex ">
                <InputModel
                  label="Date*"
                  type="date"
                  disabled={true}
                  placeholder=""
                  min={new Date().toISOString().split("T")[0]}
                  value={reservationDate}
                  //   onChange={(v) => setReservationDate(v.target.value)}
                  error={reservationDateError}
                />

                <InputModel
                  label="Heure*"
                  type="select"
                  placeholder=""
                  value={reservationTime}
                  onChange={(v) => setReservationTime(v.target.value)}
                  error={reservationTimeError}
                  options={[
                    {
                      label: "00:00",
                      value: "00:00:00",
                    },
                    {
                      label: "00:30",
                      value: "00:30:00",
                    },
                    {
                      label: "01:00",
                      value: "01:00:00",
                    },
                    {
                      label: "01:30",
                      value: "01:30:00",
                    },
                    {
                      label: "02:00",
                      value: "02:00:00",
                    },
                    {
                      label: "02:30",
                      value: "02:30:00",
                    },
                    {
                      label: "03:00",
                      value: "03:00:00",
                    },
                    {
                      label: "03:30",
                      value: "03:30:00",
                    },
                    {
                      label: "04:00",
                      value: "04:00:00",
                    },
                    {
                      label: "04:30",
                      value: "04:30:00",
                    },
                    {
                      label: "05:00",
                      value: "05:00:00",
                    },
                  ]}
                />
              </div>

              <div className="md:py-2 md:flex ">
                <InputModel
                  label="Pax*"
                  type="select"
                  placeholder=""
                  value={reservationPax}
                  onChange={(v) => setReservationPax(v.target.value)}
                  error={reservationPaxError}
                  options={(type === "The Garden - 1ere ligne"
                    ? [1, 2, 3, 4, 5, 6, 7, 8]
                    : type === "The Garden - 2eme ligne"
                    ? [1, 2, 3, 4, 5, 6]
                    : type === "Disco jungle - 1ere ligne"
                    ? [1, 2, 3, 4, 5, 6]
                    : type === "Disco jungle - 2eme ligne"
                    ? [1, 2, 3, 4]
                    : type === "BACK STAGE"
                    ? [1, 2, 3, 4, 5, 6]
                    : [1, 2, 3, 4, 5, 6]
                  ).map((nb, index) => ({
                    value: nb,
                    label: nb,
                  }))}
                />
              </div>
              <div className="md:py-2 md:flex ">
                <InputModel
                  label="Type Table*"
                  type="select"
                  placeholder=""
                  disabled={true}
                  value={reservationTypeTable}
                  error={reservationTypeTableError}
                  options={TABLETYPE?.map((type, index) => ({
                    value: type,
                    label: type,
                  }))}
                />
              </div>
              <div className="md:py-2 md:flex ">
                <InputModel
                  label="Email*"
                  type="email"
                  placeholder=""
                  // disabled={successUserProfile}
                  value={email}
                  onChange={(v) => setEmail(v.target.value)}
                  error={emailError}
                />
              </div>
              {/* fisrt name & last name */}
              <div className="md:py-2 md:flex ">
                <InputModel
                  label="Nom*"
                  type="text"
                  placeholder=""
                  value={firstName}
                  onChange={(v) => setFirstName(v.target.value)}
                  error={errorFirstName}
                />

                <InputModel
                  label="Prénom*"
                  type="text"
                  placeholder=""
                  value={lastName}
                  onChange={(v) => setLastName(v.target.value)}
                  error={errorLastName}
                />
              </div>
              <div className="md:py-2 md:w-1/2 relative  w-full ">
                <label
                  className={` absolute bg-[#FFFFFF] text-[#898989] mt-[-16px] text-[14px]  ml-[7px] px-[5px]  line-clamp-1 z-99999	`}
                >
                  Phone*
                </label>
                <PhoneInput
                  country={"ma"}
                  value={phone}
                  searchStyle={{ width: "100%" }}
                  localization={fr}
                  searchClass="w-full"
                  onChange={(phonevalue, data) => {
                    setPhone(phonevalue);

                    setCountryInfo(data);
                  }}
                />
                {errorPhone && (
                  <p className="text-[9px] italic text-danger leading-none mt-1">
                    {errorPhone}
                  </p>
                )}
              </div>

              {/*  */}
            </LayoutSection>
          </div>
        </div>
        <div className="my-2 flex flex-row items-center justify-end">
          <button
            onClick={() => {
              setEventType("cancel");
              setIsAddReservation(true);
            }}
            className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
          >
            Annuler
          </button>
          <button
            onClick={async () => {
              var check = true;
              setReservationDateError("");
              setReservationTimeError("");
              setReservationPaxError("");
              setReservationTypeTableError("");
              setReservationTableError("");
              setPrescriberError("");
              setNoteError("");
              setStatusError("");
              setACompteError("");
              setOffertError("");
              setOccassionError("");
              setErrorFirstName("");
              setErrorLastName("");
              setEmailError("");
              setErrorPhone("");
              setOtherPhoneError("");
              setErrorDateNaissance("");
              setLangueError("");
              setStatusClientError("");
              setTypeError("");

              if (reservationDate === "") {
                setReservationDateError("Ce champ est requis.");
                check = false;
              }
              if (reservationTime === "") {
                setReservationTimeError("Ce champ est requis.");
                check = false;
              }
              if (reservationPax === "") {
                setReservationPaxError("Ce champ est requis.");
                check = false;
              }
              if (reservationTypeTable === "") {
                setReservationTypeTableError("Ce champ est requis.");
                check = false;
              } else if (!TABLETYPE.includes(reservationTypeTable)) {
                setReservationTypeTableError("Ce champ est requis.");
                check = false;
              }

              // if (!isValidDateAndAfterToday(reservationDate)) {
              //   setReservationDateError("Ce champ est requis.");
              //   check = false;
              // }

              // email
              if (email === "") {
                check = false;
                setEmailError("Ce champ est obligatoire");
              } else {
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@.]{2,}$/;
                if (!regex.test(email)) {
                  check = false;
                  setEmailError("E-mail est incorrect");
                }
              }

              if (firstName === "") {
                check = false;
                setErrorFirstName("Ce champ est obligatoire");
              }
              if (lastName === "") {
                check = false;
                setErrorLastName("Ce champ est obligatoire");
              }
              if (phone === "") {
                check = false;
                setErrorPhone("Ce champ est obligatoire");
              } else {
                if (
                  !countryInfo.format ||
                  countryInfo.format === undefined ||
                  countryInfo.format === null
                ) {
                  check = false;
                  setErrorPhone("Numéro de téléphone est incorrect");
                } else {
                  // const regexPattern = countryInfo.format
                  const phoneNumber = parsePhoneNumber(
                    phone,
                    countryInfo.countryCode.toUpperCase()
                  );
                  console.log(phoneNumber);
                  if (!phoneNumber.isValid()) {
                    check = false;
                    setErrorPhone("Numéro de téléphone est incorrect");
                  }
                }
              }

              if (check) {
                setEventType("add");
                setIsAddReservation(true);
              } else {
                toast.error(
                  "Certains champs sont obligatoires veuillez vérifier"
                );
              }
            }}
            className=" bg-[darkslategrey] text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            Réserver
          </button>
        </div>
        <ConfirmationModal
          isOpen={isAddReservation}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Etes-vous sûr de vouloir confirmer cette réservation?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setIsAddReservation(false);
              setEventType("");
              setLoadEvent(false);
              navigate("/");
            } else {
              console.log("here");
              setLoadEvent(true);
              try {
                await dispatch(
                  addNewReservation({
                    client: isClientExist ? reservationClient : "",
                    reservation_date: reservationDate,
                    reservation_time: reservationTime,
                    nbr_pax: reservationPax,
                    table_type: reservationTypeTable,
                    reservation_table: reservationTable ?? "",
                    prescriber: prescriber ?? "",
                    note: note ?? "",
                    status: status ?? 1,
                    avence_payment: isNaN(parseFloat(aCompte))
                      ? 0
                      : parseFloat(aCompte) ?? 0,
                    offert: offert ?? "",
                    occasion: occassion ?? "",
                    first_name: firstName ?? "",
                    last_name: lastName ?? "",
                    date_birth: dateNaissance ?? "",
                    other_phone:
                      (phone.includes("+") ? phone : "+" + phone) ?? "",
                    phone: (phone.includes("+") ? phone : "+" + phone) ?? "",
                    email: email ?? "",
                    langue: langue ?? "",
                  })
                );
                setLoadEvent(false);
                setEventType("");
                setIsAddReservation(false);
                navigate("/success");
              } catch (error) {
                // Handle the error if needed, otherwise do nothing and stay on the current page
                setLoadEvent(false);
                setEventType("");
                setIsAddReservation(false);
                toast.error("La réservation a échoué, veuillez réessayer");
              }
            }
          }}
          onCancel={() => {
            setIsAddReservation(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </div>
  );
}

export default ConfirmScreen;
