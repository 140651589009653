import React, { useState } from "react";
import { useSelector } from "react-redux";
import logoEden from "../images/icon/logo-eden-nv.jpg";

function Menu() {
  const [isOpenNav, setIsOpenNav] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  return (
    <nav className="bg-[#183a29]  fixed w-full z-20 top-0 start-0 border-b border-[#183a29]">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a
          href="https://edenclubmarrakech.com"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img src={logoEden} className="h-16" alt="Flowbite Logo" />
        </a>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          onClick={() => setIsOpenNav(!isOpenNav)}
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${isOpenNav ? "" : "hidden"} w-full md:block md:w-auto`}
          id="navbar-default"
        >
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border  rounded-lg  md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 ">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://booking.edenclubmarrakech.com/"
                className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
                aria-current="page"
              >
                Réservations
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://events.edenclubmarrakech.com/"
                className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
              >
                Evenements
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://booking.edenclubmarrakech.com/carte/"
                className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
              >
                Carte
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://my.matterport.com/show/?m=TaihcLLdYmR&ss=1&sr=-2.89,1.22"
                className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
              >
                Visite 360
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://booking.edenclubmarrakech.com/contact/"
                className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
              >
                Contactez-nous
              </a>
            </li>
            {/* {userInfo ? (
              <li>
                <a
                  href="/"
                  className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
                >
                  Mon Profil
                </a>
              </li>
            ) : null}
            {userInfo ? (
              <li>
                <a
                  href="/logout"
                  className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
                >
                  Déconnexion
                </a>
              </li>
            ) : (
              <li>
                <a
                  href="/login"
                  className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
                >
                  Connexion
                </a>
              </li>
            )} */}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
