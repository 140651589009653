import React, { useEffect, useRef, useState } from "react";

import {
  CHANGETABLETYPESELECT,
  TABLETYPE,
  TABLETYPESELECT,
  generateDateList,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { checkReservation } from "../../redux/actions/reservationActions";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Datepicker } from "flowbite-react";
import InputModel from "../../components/InputModel";
import { login } from "../../redux/actions/userActions";
import Alert from "../../components/Alert";
import Menu from "../../layouts/Menu";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, format, getDay } from "date-fns";
import { fr } from "date-fns/locale";

function BookingScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [dates, setDates] = useState([]);
  const [selectDate, setSelectDate] = useState("");
  const dateListRef = useRef(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [selectPlan, setSelectPlan] = useState("");
  const [hoverPlan, setHoverPlan] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  const blockedDaysOfWeek = [1, 2, 3];

  const isBlockedDay = (date) => {
    const dayOfWeek = date.getDay();
    return blockedDaysOfWeek.includes(dayOfWeek);
  };

  const filterDates = (date) => {
    return !isBlockedDay(date);
  };

  useEffect(() => {
    const checkScrollPosition = () => {
      if (dateListRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = dateListRef.current;
        setIsAtStart(scrollLeft === 0);
        setIsAtEnd(scrollLeft + clientWidth === scrollWidth);
      }
    };

    const ref = dateListRef.current;
    if (ref) {
      ref.addEventListener("scroll", checkScrollPosition);
      checkScrollPosition(); // Initial check
    }

    return () => {
      if (ref) {
        ref.removeEventListener("scroll", checkScrollPosition);
      }
    };
  }, []);

  useEffect(() => {
    const dateList = generateDateList(90); // Generate dates from tomorrow to 90 days
    setDates(dateList);
    // setSelectDate(dateList[0]);
  }, []);

  const getFormatDate = (date) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const frenchFormatter = new Intl.DateTimeFormat("fr-FR", options);
    var dt = new Date(date);
    return dt;
  };

  const getMonthNameInFrench = (date) => {
    const frenchFormatter = new Intl.DateTimeFormat("fr-FR", { month: "long" });
    const monthName = frenchFormatter.format(date);
    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  };

  const scrollLeft = () => {
    if (dateListRef.current) {
      dateListRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (dateListRef.current) {
      dateListRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const reservationCheck = useSelector((state) => state.checkReservation);
  const {
    loadingReservationCheck,
    successReservationCheck,
    errorReservationCheck,
    // isFirstVip,
    // isTwoVip,
    // isBack,
    // isDance,
  } = reservationCheck;
  const isFirstVip = true;
  const isTwoVip = true;
  const isBack = true;
  const isDance = true;

  // useEffect(() => {
  //   if (selectDate !== "") {
  //     dispatch(checkReservation(selectDate));
  //   }
  // }, [selectDate]);

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate());

    // Format the date as YYYY-MM-DD
    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(tomorrow.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const [showTableLogin, setShowTableLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [emailClient, setEmailClient] = useState("");
  const [emailClientError, setEmailClientError] = useState("");

  const [errorLogin, setErrorlogin] = useState("");

  const [passwordClient, setPasswordClient] = useState("");
  const [passwordClientError, setPasswordClientError] = useState("");

  const [isOpenNav, setIsOpenNav] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);

  useEffect(() => {
    if (loginSuccess && userInfo) {
      navigate(`confirm/${selectPlan}/${selectDate}`);
      setIsLoading(false); // Ensure loading state is reset
    }
  }, [loginSuccess, userInfo, navigate, selectPlan, selectDate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    // Get data from URL query parameters
    const select = queryParams.get("select");
    if (select) {
      if (TABLETYPESELECT.includes(select)) {
        setSelectPlan(CHANGETABLETYPESELECT(select));
      }
    }
  }, []);
  return (
    <div
      className=" w-screen min-h-screen bg-[#183a29] "
      style={{
        backgroundImage: 'url("/background-eden.png")',
        backgroundPosition: "center top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Menu />
      {/*  */}
      <div className="container mx-auto  relative">
        <div className="pt-26"></div>
        <div className="flex md:flex-row flex-col-reverse justify-center items-center">
          <div className="md:w-1/3 w-full flex flex-col px-2">
            {/* "VIP 1" */}
            <div
              onClick={() => {
                setSelectPlan(
                  selectPlan === "The Garden - 1ere ligne"
                    ? ""
                    : "The Garden - 1ere ligne"
                );
                if (isFirstVip) {
                } else {
                }
              }}
              onMouseEnter={() => setHoverPlan("The Garden - 1ere ligne")}
              onMouseLeave={() => setHoverPlan("")}
              className={`${
                hoverPlan === "The Garden - 1ere ligne"
                  ? "bg-black bg-opacity-30"
                  : ""
              } my-2 flex flex-row justify-between items-center bg-[darkslategrey] text-white px-3 py-3 rounded text-sm ${
                !isFirstVip && 1 === 0
                  ? " cursor-not-allowed"
                  : "cursor-pointer"
              }`}
            >
              <div className="flex flex-row items-center">
                <div className="mx-2">
                  {selectPlan === "The Garden - 1ere ligne" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                      />
                    </svg>
                  )}
                </div>
                <div className="flex flex-col">
                  <div>The Garden - 1ere ligne</div>
                  <div className="text-[10px]">7500 Dhs (8 personnes)</div>
                  <div className="text-[8px]">DÉPENSES MINIMUM</div>
                </div>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            </div>

            {/* The Garden - 2eme ligne */}
            <div
              onClick={() => {
                setSelectPlan(
                  selectPlan === "The Garden - 2eme ligne"
                    ? ""
                    : "The Garden - 2eme ligne"
                );
                if (isFirstVip) {
                } else {
                }
              }}
              onMouseEnter={() => setHoverPlan("The Garden - 2eme ligne")}
              onMouseLeave={() => setHoverPlan("")}
              className={`${
                hoverPlan === "The Garden - 2eme ligne"
                  ? "bg-black bg-opacity-30"
                  : ""
              } my-2 flex flex-row justify-between items-center bg-[darkslategrey] text-white px-3 py-3 text-sm rounded ${
                !isFirstVip && 1 === 0
                  ? " cursor-not-allowed"
                  : "cursor-pointer"
              }`}
            >
              <div className="flex flex-row items-center">
                <div className="mx-2">
                  {selectPlan === "The Garden - 2eme ligne" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                      />
                    </svg>
                  )}
                </div>
                <div className="flex flex-col">
                  <div>The Garden - 2eme ligne</div>
                  <div className="text-[10px]">5000 Dhs (6 personnes)</div>
                  <div className="text-[8px]">DÉPENSES MINIMUM</div>
                </div>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            </div>
            {/* Disco jungle - 1ere ligne */}
            <div
              onClick={() => {
                setSelectPlan(
                  selectPlan === "Disco jungle - 1ere ligne"
                    ? ""
                    : "Disco jungle - 1ere ligne"
                );
                if (isFirstVip) {
                } else {
                }
              }}
              onMouseEnter={() => setHoverPlan("Disco jungle - 1ere ligne")}
              onMouseLeave={() => setHoverPlan("")}
              className={`${
                hoverPlan === "Disco jungle - 1ere ligne"
                  ? "bg-black bg-opacity-30"
                  : ""
              } my-2 flex flex-row justify-between items-center bg-[darkslategrey] text-white px-3 py-3 text-sm rounded ${
                !isFirstVip && 1 === 0
                  ? " cursor-not-allowed"
                  : "cursor-pointer"
              }`}
            >
              <div className="flex flex-row items-center">
                <div className="mx-2">
                  {selectPlan === "Disco jungle - 1ere ligne" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                      />
                    </svg>
                  )}
                </div>
                <div className="flex flex-col">
                  <div>Disco jungle - 1ere ligne</div>
                  <div className="text-[10px]">5000 Dhs (6 personnes)</div>
                  <div className="text-[8px]">DÉPENSES MINIMUM</div>
                </div>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            </div>
            {/* Disco jungle - 2eme ligne */}
            <div
              onClick={() => {
                setSelectPlan(
                  selectPlan === "Disco jungle - 2eme ligne"
                    ? ""
                    : "Disco jungle - 2eme ligne"
                );
                if (isFirstVip) {
                } else {
                }
              }}
              onMouseEnter={() => setHoverPlan("Disco jungle - 2eme ligne")}
              onMouseLeave={() => setHoverPlan("")}
              className={`${
                hoverPlan === "Disco jungle - 2eme ligne"
                  ? "bg-black bg-opacity-30"
                  : ""
              } my-2 flex flex-row justify-between items-center bg-[darkslategrey] text-white px-3 py-3 text-sm rounded ${
                !isFirstVip && 1 === 0
                  ? " cursor-not-allowed"
                  : "cursor-pointer"
              }`}
            >
              <div className="flex flex-row items-center">
                <div className="mx-2">
                  {selectPlan === "Disco jungle - 2eme ligne" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                      />
                    </svg>
                  )}
                </div>
                <div className="flex flex-col">
                  <div>Disco jungle - 2eme ligne</div>
                  <div className="text-[10px]">2500 Dhs (4 personnes)</div>
                  <div className="text-[8px]">DÉPENSES MINIMUM</div>
                </div>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            </div>

            {/* "BACK STAGE" */}
            <div
              onClick={() => {
                setSelectPlan(selectPlan === "BACK STAGE" ? "" : "BACK STAGE");
                if (isBack) {
                } else {
                }
              }}
              onMouseEnter={() => setHoverPlan("BACK STAGE")}
              onMouseLeave={() => setHoverPlan("")}
              className={`my-2  ${
                hoverPlan === "BACK STAGE" ? "bg-black bg-opacity-30" : ""
              }  flex flex-row justify-between items-center bg-[darkslategrey] text-white px-3 py-3 rounded ${
                !isBack ? " cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <div className="flex flex-row items-center">
                <div className="mx-2">
                  {selectPlan === "BACK STAGE" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                      />
                    </svg>
                  )}
                </div>
                <div className="flex flex-col">
                  <div>BACK STAGE</div>
                  <div className="text-[10px]">10000 Dhs (6 personnes)</div>
                  <div className="text-[8px]">DÉPENSES MINIMUM</div>
                </div>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            </div>
            {/* "DANCE FLOOR" */}
            <div
              onClick={() => {
                setSelectPlan(
                  selectPlan === "DANCE FLOOR" ? "" : "DANCE FLOOR"
                );
                if (isDance) {
                } else {
                }
              }}
              onMouseEnter={() => setHoverPlan("DANCE FLOOR")}
              onMouseLeave={() => setHoverPlan("")}
              className={`my-2  ${
                hoverPlan === "DANCE FLOOR" ? "bg-black bg-opacity-30" : ""
              } flex flex-row justify-between items-center bg-[darkslategrey] text-white px-3 py-3 rounded ${
                !isDance ? " cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <div className="flex flex-row items-center">
                <div className="mx-2">
                  {selectPlan === "DANCE FLOOR" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                      />
                    </svg>
                  )}
                </div>
                <div className="flex flex-col">
                  <div>DANCE FLOOR</div>
                  <div className="text-[10px]">2500 Dhs (6 personnes)</div>
                  <div className="text-[8px]">DÉPENSES MINIMUM</div>
                </div>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            </div>
            <div className="w-full">
              <button
                onClick={() => {
                  if (selectDate !== "" && selectPlan !== "") {
                    // if (!userInfo) {
                    //   // show login
                    //   setShowTableLogin(true);
                    //   setEmailClient("");
                    //   setEmailClientError("");
                    //   setPasswordClient("");
                    //   setPasswordClientError("");
                    //   setErrorlogin("");

                    //   //
                    // } else {

                    navigate(
                      "confirm/" +
                        selectPlan +
                        "/" +
                        format(selectDate, "yyyy-MM-dd")
                    );
                    // }
                  } else {
                    if (selectDate !== "") {
                      toast.error(
                        "Veuillez sélectionner la date de réservation"
                      );
                    }
                    if (selectPlan !== "") {
                      toast.error("Veuillez sélectionner le type de table");
                    }
                  }
                }}
                type="button"
                className={`text-white my-2 text-xl bg-[darkslategrey]  ${
                  selectDate === "" || selectPlan === ""
                    ? "cursor-not-allowed bg-gray-400"
                    : "cursor-pointer"
                } font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full`}
                disabled={selectDate === "" || selectPlan === ""}
              >
                Réserver
              </button>
            </div>
          </div>
          {/* images */}
          <div className="md:w-2/3 w-full flex flex-col px-2">
            <div className="flex md:flex-row flex-col md:justify-between items-center ">
              <div className="relative md:max-w-sm w-full my-2">
                {/* <input
                  datepicker
                  datepicker-autohide
                  type="date"
                  
                  value={selectDate}
                  onChange={(v) => setSelectDate(v.target.value)}
                  min={getTomorrowDate()}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Veuillez sélectionner une date"
                /> */}
                <DatePicker
                  minDate={new Date()}
                  selected={selectDate}
                  onChange={(date) => setSelectDate(date)}
                  filterDate={filterDates}
                  className="bg-gray-50 border w-max border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  px-5 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholderText="Sélectionner une date"
                  dateFormat="yyyy-MM-dd"
                  locale={fr}
                />
              </div>
            </div>
            <div>
              <div className="overflow-y-auto py-3 hide-scrollbar ">
                <div className="flex flex-row items-center md:h-[30rem]  h-[15rem] md:w-[60rem] w-[30rem] mx-auto  relative ">
                  {/* image vip 1 */}
                  <img
                    src={
                      "https://epiceventsstatic.s3.eu-west-3.amazonaws.com/animation-eden/plans_eden.png"
                    }
                    alt="Eden PLAN"
                    className="w-full h-full bg-contain"
                  />
                  {/* The Garden - 2eme ligne */}
                  <div
                    onClick={() => {
                      if (isFirstVip) {
                        setSelectPlan(
                          selectPlan === "The Garden - 2eme ligne"
                            ? ""
                            : "The Garden - 2eme ligne"
                        );
                      } else {
                      }
                    }}
                    onMouseEnter={() => setHoverPlan("The Garden - 2eme ligne")}
                    onMouseLeave={() => setHoverPlan("")}
                    className={` ${
                      !isFirstVip ? " cursor-not-allowed" : "cursor-pointer"
                    } ${
                      selectPlan === "The Garden - 2eme ligne"
                        ? "bg-black bg-opacity-30 border border-gray-600"
                        : ""
                    }  absolute text-white ${
                      hoverPlan === "The Garden - 2eme ligne"
                        ? "bg-black bg-opacity-30"
                        : ""
                    } hover:bg-black hover:bg-opacity-30 md:left-16 left-8 md:bottom-[8rem] bottom-[4rem]   md:w-[10rem] w-[5rem] md:h-[8rem] h-[4rem]  items-center text-center flex flex-col justify-center  text-sm`}
                  >
                    <div className="text-xs">The Garden - 2eme ligne</div>

                    {selectPlan === "The Garden - 2eme ligne" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                        />
                      </svg>
                    )}
                  </div>
                  {/* The Garden - 1ere ligne */}
                  <div
                    onClick={() => {
                      if (isFirstVip) {
                        setSelectPlan(
                          selectPlan === "The Garden - 1ere ligne"
                            ? ""
                            : "The Garden - 1ere ligne"
                        );
                      } else {
                      }
                    }}
                    onMouseEnter={() => setHoverPlan("The Garden - 1ere ligne")}
                    onMouseLeave={() => setHoverPlan("")}
                    className={` ${
                      !isFirstVip ? " cursor-not-allowed" : "cursor-pointer"
                    } ${
                      selectPlan === "The Garden - 1ere ligne"
                        ? "bg-black bg-opacity-30 border border-gray-600"
                        : ""
                    }  absolute text-white ${
                      hoverPlan === "The Garden - 1ere ligne"
                        ? "bg-black bg-opacity-30"
                        : ""
                    } hover:bg-black hover:bg-opacity-30 md:left-[14rem] left-[7rem] md:bottom-[8rem] bottom-[4rem]   md:w-[10rem] w-[5rem] md:h-[8rem] h-[4rem]  items-center text-center flex flex-col justify-center  text-sm`}
                  >
                    <div className="text-xs">The Garden - 1ere ligne</div>

                    {selectPlan === "The Garden - 1ere ligne" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                        />
                      </svg>
                    )}
                  </div>
                  {/* Disco jungle - 2eme ligne */}
                  <div
                    onClick={() => {
                      if (isTwoVip) {
                        setSelectPlan(
                          selectPlan === "Disco jungle - 2eme ligne"
                            ? ""
                            : "Disco jungle - 2eme ligne"
                        );
                      } else {
                      }
                    }}
                    onMouseEnter={() =>
                      setHoverPlan("Disco jungle - 2eme ligne")
                    }
                    onMouseLeave={() => setHoverPlan("")}
                    className={`  ${
                      !isTwoVip ? " cursor-not-allowed" : "cursor-pointer"
                    } ${
                      selectPlan === "Disco jungle - 2eme ligne"
                        ? "bg-black bg-opacity-30 border border-gray-600"
                        : ""
                    } ${
                      hoverPlan === "Disco jungle - 2eme ligne"
                        ? "bg-black bg-opacity-30"
                        : ""
                    }  absolute text-white hover:bg-black hover:bg-opacity-30  md:right-16 right-8 md:bottom-[8rem] bottom-[4rem]   md:w-[10rem] w-[5rem] md:h-[8rem] h-[4rem]  items-center text-center flex flex-col justify-center  text-sm`}
                  >
                    <div className="text-xs">Disco jungle - 2eme ligne</div>

                    {selectPlan === "Disco jungle - 2eme ligne" && (
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  {/* Disco jungle - 1ere ligne */}
                  <div
                    onClick={() => {
                      if (isTwoVip) {
                        setSelectPlan(
                          selectPlan === "Disco jungle - 1ere ligne"
                            ? ""
                            : "Disco jungle - 1ere ligne"
                        );
                      } else {
                      }
                    }}
                    onMouseEnter={() =>
                      setHoverPlan("Disco jungle - 1ere ligne")
                    }
                    onMouseLeave={() => setHoverPlan("")}
                    className={`  ${
                      !isTwoVip ? " cursor-not-allowed" : "cursor-pointer"
                    } ${
                      selectPlan === "Disco jungle - 1ere ligne"
                        ? "bg-black bg-opacity-30 border border-gray-600"
                        : ""
                    } ${
                      hoverPlan === "Disco jungle - 1ere ligne"
                        ? "bg-black bg-opacity-30"
                        : ""
                    }  absolute text-white hover:bg-black hover:bg-opacity-30  md:right-[14rem] right-[7rem] md:bottom-[8rem] bottom-[4rem]   md:w-[10rem] w-[5rem] md:h-[8rem] h-[4rem]  items-center text-center flex flex-col justify-center  text-sm`}
                  >
                    <div className="text-xs">Disco jungle - 1ere ligne</div>

                    {selectPlan === "Disco jungle - 1ere ligne" && (
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                          />
                        </svg>
                      </div>
                    )}
                  </div>

                  {/* back stage  */}
                  <div
                    onClick={() => {
                      if (isBack) {
                        setSelectPlan(
                          selectPlan === "BACK STAGE" ? "" : "BACK STAGE"
                        );
                      } else {
                      }
                    }}
                    onMouseEnter={() => setHoverPlan("BACK STAGE")}
                    onMouseLeave={() => setHoverPlan("")}
                    className={`  ${
                      !isBack ? " cursor-not-allowed" : " cursor-pointer"
                    } ${
                      selectPlan === "BACK STAGE"
                        ? "bg-black bg-opacity-30 border  border-gray-600"
                        : ""
                    } ${
                      hoverPlan === "BACK STAGE" ? "bg-black bg-opacity-30" : ""
                    }  absolute text-white hover:bg-black hover:bg-opacity-30 md:right-[22rem] right-[11rem] md:top-[3.5rem] top-[1.75rem]  md:w-[17rem] w-[8.5rem] md:h-[12rem] h-[6rem]  items-center text-center flex flex-col justify-center  text-sm`}
                  >
                    <div className="text-xs">BACK STAGE</div>
                    {/* {selectPlan === "BACK STAGE" ||
                    hoverPlan === "BACK STAGE" ? (
                      <div>
                        <div className="text-[8px]">4000 Dhs</div>
                        <div className="text-[8px]">DÉPENSES MINIMUM</div>
                      </div>
                    ) : null} */}

                    {selectPlan === "BACK STAGE" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                        />
                      </svg>
                    )}
                  </div>

                  {/* dance floor */}
                  <div
                    onClick={() => {
                      if (isDance) {
                        setSelectPlan(
                          selectPlan === "DANCE FLOOR" ? "" : "DANCE FLOOR"
                        );
                      } else {
                      }
                    }}
                    onMouseEnter={() => setHoverPlan("DANCE FLOOR")}
                    onMouseLeave={() => setHoverPlan("")}
                    className={`  ${
                      !isDance ? " cursor-not-allowed" : "cursor-pointer"
                    } ${
                      selectPlan === "DANCE FLOOR"
                        ? "bg-black bg-opacity-30 border border-gray-600"
                        : ""
                    } ${
                      hoverPlan === "DANCE FLOOR"
                        ? "bg-black bg-opacity-30"
                        : ""
                    }  absolute text-white hover:bg-black hover:bg-opacity-30 md:right-[22rem] right-[11rem] md:bottom-[4.5rem] bottom-[2.25rem] md:w-[15rem] w-[7.5rem] md:h-[10rem] h-[5rem]  items-center text-center flex flex-col justify-center  text-sm`}
                  >
                    <div className="text-xs">DANCE FLOOR</div>
                    {/* {selectPlan === "DANCE FLOOR" ||
                    hoverPlan === "DANCE FLOOR" ? (
                      <div>
                        <div className="text-[8px]">2000 Dhs</div>
                        <div className="text-[8px]">DÉPENSES MINIMUM</div>
                      </div>
                    ) : null} */}
                    {selectPlan === "DANCE FLOOR" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-10"></div>
      </div>
      {/*  */}
      <div className="container mx-auto  relative">
        {/* <div className="text-white pt-26 ">Veuillez sélectionner une date</div>
        <div className="flex items-center">
          {!isAtStart && (
            <button
              onClick={scrollLeft}
              className="p-2 m-2 bg-gray-300 rounded-full"
            >
              &larr;
            </button>
          )}
          <div
            className=" flex flex-row overflow-y-auto py-3 hide-scrollbar "
            ref={dateListRef}
          >
            {dates.map((date, index) => (
              <div
                onClick={() => {
                  setSelectDate(date);
                }}
                className={` border rounded-md p-3 shadow shadow-blue-500/50   text-center mx-2 py-3 my-2 cursor-pointer ${
                  selectDate === date
                    ? "border-green-500 text-green-500 font-bold"
                    : "border-white text-white"
                }`}
                key={index}
              >
                <div className=" text-center w-25">
                  {getFormatDate(date).getDate().toString().padStart(2, "0")}
                  <br />
                  {getMonthNameInFrench(getFormatDate(date))}
                  <br />
                  {getFormatDate(date).getFullYear()}
                </div>
              </div>
            ))}
          </div>
          {!isAtEnd && (
            <button
              onClick={scrollRight}
              className="p-2 m-2 bg-gray-300 rounded-full"
            >
              &rarr;
            </button>
          )}
        </div>
        <div className="text-white pt-5 ">Veuillez sélectionner une Type</div>
         */}

        {/* <div className="overflow-y-auto py-3 hide-scrollbar ">
          <div className="flex flex-row items-center h-[30rem] w-[60rem] mx-auto bg-[#183a29] relative ">
            <img
              src={planEden}
              alt="VIP 1 PLAN"
              className="w-full h-full bg-contain"
            />
            <div
              onClick={() => {
                if (isFirstVip) {
                  setSelectPlan(selectPlan === "VIP 1" ? "" : "VIP 1");
                } else {
                }
              }}
              className={` ${
                !isFirstVip ? " cursor-not-allowed" : "cursor-pointer"
              } ${
                selectPlan === "VIP 1"
                  ? "bg-black bg-opacity-30 border border-gray-600"
                  : ""
              }  absolute text-white hover:bg-black hover:bg-opacity-30 left-16 bottom-[8rem]   w-[20rem] h-[8rem]  items-center text-center flex flex-col justify-center  text-sm`}
            >
              <div>VIP 1</div>

              {selectPlan === "VIP 1" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                  />
                </svg>
              )}
            </div>
            <div
              onClick={() => {
                if (isTwoVip) {
                  setSelectPlan(selectPlan === "VIP 2" ? "" : "VIP 2");
                } else {
                }
              }}
              className={`  ${
                !isTwoVip ? " cursor-not-allowed" : "cursor-pointer"
              } ${
                selectPlan === "VIP 2"
                  ? "bg-black bg-opacity-30 border border-gray-600"
                  : ""
              }  absolute text-white hover:bg-black hover:bg-opacity-30 right-16 bottom-[8rem]   w-[20rem] h-[8rem]  items-center text-center flex flex-col justify-center  text-sm`}
            >
              <div>VIP 2</div>

              {selectPlan === "VIP 2" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                  />
                </svg>
              )}
            </div>

            <div
              onClick={() => {
                if (isBack) {
                  setSelectPlan(
                    selectPlan === "BACK STAGE" ? "" : "BACK STAGE"
                  );
                } else {
                }
              }}
              className={`  ${
                !isBack ? " cursor-not-allowed" : " cursor-pointer"
              } ${
                selectPlan === "BACK STAGE"
                  ? "bg-black bg-opacity-30 border  border-gray-600"
                  : ""
              }  absolute text-white hover:bg-black hover:bg-opacity-30 right-[22rem] top-[3.5rem]   w-[17rem] h-[12rem]  items-center text-center flex flex-col justify-center  text-sm`}
            >
              <div>BACK STAGE</div>

              {selectPlan === "BACK STAGE" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                  />
                </svg>
              )}
            </div>

            <div
              onClick={() => {
                if (isDance) {
                  setSelectPlan(
                    selectPlan === "DANCE FLOOR" ? "" : "DANCE FLOOR"
                  );
                } else {
                }
              }}
              className={`  ${
                !isDance ? " cursor-not-allowed" : "cursor-pointer"
              } ${
                selectPlan === "DANCE FLOOR"
                  ? "bg-black bg-opacity-30 border border-gray-600"
                  : ""
              }  absolute text-white hover:bg-black hover:bg-opacity-30 right-[22rem] bottom-[4.5rem] w-[15rem] h-[10rem]  items-center text-center flex flex-col justify-center  text-sm`}
            >
              <div>DANCE FLOOR</div>
              {selectPlan === "DANCE FLOOR" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                  />
                </svg>
              )}
            </div>
          </div>
        </div> */}
      </div>

      {/* login */}
      {showTableLogin ? (
        <div className="w-screen h-screen bg-black bg-opacity-30 fixed top-0 z-99999 flex items-center justify-center">
          <div className="bg-white px-6 pt-5 pb-3 m-3 rounded shadow-2xl md:w-[580px] w-full">
            <div className="mb-8 font-bold text-center text-xl ">
              Connectez-vous à votre compte
            </div>
            {errorLogin !== "" ? (
              <div className="mb-5">
                <Alert type="error" message={error} />
              </div>
            ) : null}

            <div className="mb-4">
              <InputModel
                label="Email*"
                type="email"
                placeholder=""
                disabled={isLoading}
                value={emailClient}
                onChange={(v) => setEmailClient(v.target.value)}
                error={emailClientError}
              />
            </div>
            <div className="mb-4">
              <InputModel
                label="Mot de passe*"
                type="password"
                placeholder=""
                disabled={isLoading}
                value={passwordClient}
                onChange={(v) => setPasswordClient(v.target.value)}
                error={passwordClientError}
              />
            </div>
            <div className="mb-4 flex flex-row justify-center items-center">
              <button
                onClick={async () => {
                  var check = true;
                  setEmailClientError("");
                  setPasswordClientError("");

                  if (emailClient === "") {
                    setEmailClientError("Veuillez entrer votre email");
                    check = false;
                  } else {
                    const regex = /^[^\s@]+@[^\s@]+\.[^\s@.]{2,}$/;
                    const textEmail = regex.test(emailClient);
                    if (!textEmail) {
                      setEmailClientError(
                        "Votre email n'est pas valide, veuillez entrer votre email correct"
                      );
                      check = false;
                    }
                  }

                  if (passwordClient === "") {
                    setEmailClientError("Veuillez entrer votre Mot de passe");
                    check = false;
                  }

                  if (check) {
                    setErrorlogin("");
                    setIsLoading(true);
                    try {
                      await dispatch(login(emailClient, passwordClient));
                      setLoginSuccess(true);
                      setIsLoading(false); // Set login success to true on successful login
                    } catch (error) {
                      console.error(error);
                      setIsLoading(false);
                    }
                    setErrorlogin(error);
                  } else {
                    toast.error("Veuillez confirmer les champs obligatoires");
                  }
                }}
                disabled={isLoading}
                className="text-white bg-[darkslategrey] rounded mx-2 px-3 py-2"
              >
                {isLoading ? (
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 mx-2 text-gray-200 animate-spin dark:text-gray-600 fill-danger"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Connexion"
                )}
              </button>
              <button
                disabled={isLoading}
                onClick={() => {
                  setShowTableLogin(false);
                  setEmailClient("");
                  setEmailClientError("");
                  setPasswordClient("");
                  setPasswordClientError("");
                }}
                className="text-white bg-danger rounded mx-2 px-3 py-2"
              >
                Annuler
              </button>
            </div>
            <div className="flex  flex-col items-center justify-between mb-4">
              <a
                className="inline-block align-baseline font-bold text-sm text-[darkslategrey] hover:text-blue-800"
                href="#!"
              >
                Mot de passe oublié ?
              </a>
              <div className="flex flex-row justify-between items-center">
                <div className=" w-5 h-[2px] bg-bodydark2"></div>
                <div className=" mx-3 text-sm">OU</div>
                <div className=" w-5 h-[2px] bg-bodydark2"></div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="">
                  Nouveau compte?{" "}
                  <a href="/inscription" className=" mx-1 text-[darkslategrey]">
                    S'inscrire
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default BookingScreen;
