import "./App.css";
import "./axios.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import BookingScreen from "./screens/booking/BookingScreen.js";
import ConfirmScreen from "./screens/booking/ConfirmScreen.js";
import SuccessScreen from "./screens/booking/SuccessScreen.js";
import ProfileScreen from "./screens/profile/ProfileScreen.js";
import LogoutScreen from "./screens/auth/LogoutScreen.js";
import LoginScreen from "./screens/auth/LoginScreen.js";
import InscriptionScreen from "./screens/auth/InscriptionScreen.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <BookingScreen />,
  },
  {
    path: "/confirm/:type/:date",
    element: <ConfirmScreen />,
  },
  {
    path: "/success",
    element: <SuccessScreen />,
  },
  // {
  //   path: "/profile",
  //   element: <ProfileScreen />,
  // },
  // {
  //   path: "/login",
  //   element: <LoginScreen />,
  // },
  // {
  //   path: "/inscription",
  //   element: <InscriptionScreen />,
  // },
  // {
  //   path: "/logout",
  //   element: <LogoutScreen />,
  // },
  {
    path: "*",
    element: <BookingScreen />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
